<template>
    <el-scrollbar class="padding10" height='100%' >
        <div class="n-tabs">
            <span  class="n-btn" :class="{'active':itemType=='1'}" @click="swithChangeFun('1')">指标单选</span>
            <span class="n-btn" :class="{'active':itemType=='2'}" @click="swithChangeFun('2')">指标多选</span>
            <el-button type="primary" style="position: absolute;bottom: 3px;right: 10px;" size="small" @click="submitFun('submit')">提交</el-button>
            <el-button type="primary" style="position: absolute;bottom: 3px;right: 70px;" size="small" @click="submitFun('preview')">预览</el-button>
        </div>
        <br>
        <el-row   style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10"><span>地区</span></div>
            </el-col>
            <el-col :span="21">
                <el-radio-group v-model="level"  class="cus-radio-g" disabled>
                    <el-radio v-for='item in regionLevelList' :key='item.id'  :label="item.id" size="large" @change='cgChangeFun()'>{{item.name}}</el-radio>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-row  v-if='itemType==2'  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10"><span>指标周期</span></div>
            </el-col>
            <el-col :span="21">
                <el-radio-group v-model="itemCycle"  class="cus-radio-g" >
                    <el-radio v-for='item in itemCycleList' :key="item.id" :label="item.id" size="large" @change='cgChangeFun()'>{{item.name}}</el-radio>
                </el-radio-group>
            </el-col>
        </el-row>
        
        <el-row  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>指标项</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-tag v-for='t in items' :key='t.id' closable class="tag-cus"   @close="delItem(t.id)">
                        {{t.itemName}}
                    </el-tag>
                    <i v-if='(!isShowItem && itemType=="1" && items.length<1)||(!isShowItem && itemType=="2")' class="iconfont ec-icon-circle-plus-outline txt-color" style="font-size: 20px;cursor: pointer;vertical-align: middle;"  @click="showItemFun"></i>
                </div>
            </el-col>
        </el-row>
        <div v-if='isShowItem'>
            <el-empty v-if='userFavoritesList.length<1' description="收藏夹还没有指标项快去添加吧~" />
            <div v-else  class="fav  clearfix">
                <i class="iconfont ec-icon-circle-close c-close" @click='isShowItem=false'></i>
                <el-scrollbar class="fav-left" >
                    <ul>
                        <li v-for='item in userFavoritesList' :key="item.id" :class="{'cur':item.id==favId}" @click.stop='getFavListFun(item.id)'>
                            <span>{{item.name}}</span>
                        </li>
                    </ul>
                </el-scrollbar>
                <el-scrollbar class="fav-right" >
                    <span class="item-tag " v-for='item in favList' :key='item.id' :class="{'is-disabled':item.isDisabled,'is-sel':items.filter(t=>t.itemId==item.itemId && t.objId==item.objId).length>0}" @click.stop="selItemFun(item)">{{item.itemName}}</span>
                </el-scrollbar>
            </div>
        </div>
        <el-row  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>呈现方式</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-radio-group v-model="showMode" class="cus-radio-g" @change="radioChange(1)">
                    <el-radio label="chart_line" size="large">趋势(折线图)</el-radio>
                    <el-radio label="chart_bar" size="large">趋势(柱状图)</el-radio>
                    <el-radio v-if='itemType==2' label="chart_pie" size="large">占比(饼图)</el-radio>
                    <el-radio v-if='itemType==1 && level>0' label="list_sort" size="large">排名</el-radio>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>
        <!-- 对有预测的指标项选中折线图时，再次确认是否显示预测 -->
        <el-row   v-if='showMode.indexOf("chart_")>=0 && itemType==1 && items.length>0 &&  items[0]?.isForecast=="1"' style="line-height:40px;" >
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>预测数据</span>
                </div>
            </el-col>
            <el-col :span="21">
                <el-switch
                    v-model="isForecast"
                    class="ml-2"
                    style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                ></el-switch>
            </el-col>
        </el-row>
        <!-- 选则排名时，对谁进行排名 -->
        <el-row   v-if='showMode=="list_sort"' style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>排名</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-radio-group v-model="rule"  class="cus-radio-g" @change="radioChange(2)">
                    <el-radio label="grade" size="large">同级</el-radio>
                    <el-radio v-if='level>1' label="province" size="large">本省</el-radio>
                    <el-radio v-if='level>2' label="city" size="large">本市</el-radio>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>
        <el-row   v-if='itemType=="2" && ( showMode=="chart_line" || showMode=="chart_bar")' style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>纵轴</span>
                </div>
            </el-col>
            <el-col :span="21">
                <el-radio-group v-model="yAxisType"  class="cus-radio-g" >
                    <el-radio label="single" size="large">单轴</el-radio>
                    <el-radio label="multiple" :disabled='yAxisList.length==1' size="large">多轴</el-radio>
                </el-radio-group>
                <div v-if="yAxisType=='multiple'">
                    <span v-for='(item,index) in yAxisList' :key="item.key" class='span-nowrap'>
                        轴{{index+1}}:{{item.key}}
                    </span>
                </div>
            </el-col>
        </el-row>
        <el-row   v-if='showMode=="list_sort" || showMode=="chart_pie"' style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10">
                    <span>日期</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-checkbox v-model="isCurDate" disabled>应用数据最新日期</el-checkbox>
                </div>
            </el-col>
        </el-row>
        <el-row  style="line-height:40px;">
            <el-col :span="3">
                <div class="txt-r pr-10"><span>标题</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-input v-model="title" maxlength="20" placeholder="标题名称" show-word-limit type="text"/>
                </div>
            </el-col>
        </el-row>
        <el-row  style="line-height:40px;padding-top: 10px;">
            <el-col :span="3" >
                <div class="txt-r pr-10">
                    <span>描述</span>
                </div>
            </el-col>
            <el-col :span="21">
                <div>
                    <el-input v-model="itemDesc"
                        maxlength="200"
                        placeholder="请输入描述"
                        show-word-limit
                        type="textarea"/>
                </div>
            </el-col>
        </el-row>
        <div v-if='isShowPreview' style="margin-top:10px;" >
            <br>
            <el-divider class='cus-divider'>
                <span>预览</span>
                <el-tooltip class="item" effect="dark" content="针对某地区数据的预览。数据日期，折线、柱状图为历年日期，饼图、排名为数据最新日期" placement="top-start">
                    <i class="iconfont ec-icon-warning i-tooltip"></i>
                </el-tooltip>
            </el-divider>
            <div  style="padding:10px;height:350px;">
                <div v-if='showMode=="list_sort"' style="width:100%;height:100%;">
                    <el-table v-loading="tableLoadingShow" 
                        element-loading-text="数据正在加载中..."
                        :data="sortDtInfo" stripe height="100%" style="width: 100%;">
                        <el-table-column type="index" label="排名" width="80" />
                        <el-table-column prop="regionName" label="地区"  />
                        <el-table-column prop="itemValue" label="值"  />
                    </el-table>
                </div>
                <div v-if='showMode!="list_sort"' id='chart' style="width:100%;height:100%;"></div>
            </div>
        </div>
    </el-scrollbar>
</template>
<script>
import { ref, toRef } from '@vue/reactivity';
import {getUserFavorites,getFavList,addAreaEco,addUserLog} from '@/http/basicsApi.js'
import {getRenewalCycleList,getRegionItemSort,getRegionItemLine,getRegionItemPie} from '@/http/ecoApi.js'
import { ElMessage  } from 'element-plus'
import * as echarts from 'echarts'
import {parseTimeOfCycle,getList} from '@/utils'
import {useStore} from 'vuex'
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
export default {
    props: {
         themeId:{
            type:Number
         },
         regionLevel:{
             type:Number,
             default:-1
         }
    },
    emits: ['refreshFun'],
    setup(props,{ emit }) {
        const route=useRoute();
        let currentUser=ref(JSON.parse(localStorage.getItem('currentUser')));//当前用户信息
        //区域经济的基本信息参数
        let itemType=ref(1);//指标类型，1：单选，2：多选
        let isShowItem=ref(false);//是否显示指标项列表
        let items=ref([]);//选中的指标项
        let showMode=ref('');//呈现方式
        let rule=ref('');//排名规则，默认同级
        let isForecast=ref(false);//是否显示预测
        let title=ref('');//标题
        let itemDesc=ref('');//描述
        let itemCycleList=ref([]);//指标等级
        let regionLevelList=computed(()=>useStore().state.regionLevelList);
        const level=toRef(props,'regionLevel');//地区等级，单选框要双向绑定参数
        let itemCycle=ref('');//指标周期
        let yAxisType=ref('single');//y轴显示单轴还是多轴，多轴以单位不同而分为多轴
        let isCurDate=ref(true);//是否应用最新日期
        let isShowPreview=ref(false);//是否显示预览
        ///区域经济编辑
        //1、指标类型的改变，单选、多选
        const swithChangeFun=(val)=>{
            console.log(val);
            itemType.value=val;
            //指标项、地区清空、呈现方式、排序规则
            itemCycle.value='';
            isShowItem.value=false;
            items.value=[];
            showMode.value="";
            rule.value=''; 
            isShowPreview.value=false;
        }
        // 指标周期的选择\指标等级的选择
        const cgChangeFun=()=>{
            items.value=[];//指标项
            isShowItem.value=false;
            showMode.value='';//呈现方式
            rule.value='';//规则
            isShowPreview.value=false;//预览
        }
        //2、显示指标项列表
        const showItemFun=()=>{
            isShowItem.value=true;
            //重置收藏夹为第一个
            getFavListFun(userFavoritesList.value[0].id)
        }
        //3、选择指标项
        let yAxisList=ref([]);//y轴列表
        const selItemFun=(item)=>{
            isForecast.value=false;
            isShowPreview.value=false;
            if(itemType.value==1 && item.isDisabled==false){//单选
                isShowItem.value=!isShowItem.value
                items.value.push(item);
                yAxisList.value=[];  
            }else if(itemType.value==2){
                //指标数量限制为50个
                if(items.value.length>=50){
                    ElMessage.warning({
                    message: "指标数量已达到限量" 
                    }); 
                    return false;
                }
                let filterAry= items.value.filter(t=>t.itemId==item.itemId && t.objId==item.objId);
                if(item.isDisabled==false && filterAry.length<1){
                    items.value.push(item)
                }
                items.value=items.value.map((t,index)=>{
                    return { ...t,id:index+1}
                });
                //根据选中的指标项进行分组，计算是否多个y轴
                yAxisList.value= getList(items.value,'itemUnit');
                //如果只有一个单位就是单轴
                if(yAxisList.value.length<=1){
                    yAxisType.value="single";
                }
            }
        }
        //删除选择的指标项
        const delItem=(id)=>{
            let index=items.value.map(t=>t.id).indexOf(id);
            items.value.splice(index,1);
            isShowPreview.value=false;
            //根据选中的指标项进行分组，计算是否多个y轴
            yAxisList.value= getList(items.value,'itemUnit');
            //如果只有一个单位就是单轴
            if(yAxisList.value.length<=1){
                yAxisType.value="single";
            }
        }
        //排序的单选的改变
        const radioChange=(type)=>{
            console.log('单选',type);
            if(type==1){
                isForecast.value=false;
                rule.value=''
            }
            isShowPreview.value=false;
        }
        //获取收藏夹列表
        let userFavoritesList=ref([]);//收藏夹列表
        let favId=ref('');//选中的收藏夹
        const getUserFavoritesFun=async()=>{
            console.log(currentUser.value);
            const result=await getUserFavorites(currentUser.value.userId);
            //console.log(result);
            if(result.code==20000){
                userFavoritesList.value=result.data.filter(t=>t.itemNum>0);
            }
        }
        //获取收藏夹的指标项列表
        let favList=ref([]);//指标项列表
        const getFavListFun=async(id)=>{
            favId.value=id;
            let param={
                favId:parseInt( id),
                pageSize:1,
                pageIndex:1
            }
            console.log(param);
            const result=await getFavList(param);
            console.log(result);
            if(result.code==20000){
                favList.value=result.data.dataList
                favList.value.forEach(t=>{
                    let gradeAry=[...t.itemGrade];
                    if(itemType.value==1 && level.value>-1 && gradeAry[level.value+1]=="1"){
                        t.isDisabled=false
                    }
                    else if(itemType.value==2 && level.value>-1 && gradeAry[level.value+1]=="1" && t.itemCycle==itemCycle.value){
                        t.isDisabled=false
                    }
                    else{
                        t.isDisabled=true;
                    }
                })
            }
        }
        //4、获取地区列表
        //let regionList=ref([]);//地区列表
        // const getAllRegionListFun=async()=>{
        //     const result=await getAllRegionList();
        //     console.log(result);
        //     if(result.code==20000){
        //         regionList.value=result.data;
        //     }
        // }
        //获取指标周期
        const getItemCycleList=async()=>{
            const result=await getRenewalCycleList();
            if(result.code==20000){
                console.log(result);
                itemCycleList.value=result.data;
            }
        }
        // //获取地区等级
        // const getGradeList=async()=>{
        //     const result=await getGradeList();
        //     if(result.code==20000){
        //         gradeList.value=result.data.filter(t=>t.id>2);
        //     }
        // }
        //获取指标周期
        let sortDtInfo=ref([]);//排序列表
        let tableLoadingShow=ref(false);//表格中数据正在加载中的显示
        let myChart=null;//图表实例
        let dataDate=ref('');//预览时数据时间
        //5、提交，添加区域经济部件
        const submitFun=async(type)=>{
            let msg='';
            if(props.themeId<1){
                msg="无主题"
            }
            else if(items.value.length<1){
                msg='无指标项'
            }
            else if(itemType.value==2 && items.value.length<2){
                msg='指标项不能少于一个'
            }
            else if(!level.value<0){
                msg='无地区'
            }
            else if(!showMode.value){
                msg="无呈现方式"
            }
            else if(showMode.value=='list_sort' && !rule.value){
                msg="无排名规则"
            }
            else if(!title.value && type=='submit'){
                msg="无标题"
            }
            //多轴不能多余3个
            else if((showMode.value=='chart_line' || showMode.value=='chart_bar') && yAxisType.value=="multiple" && yAxisList.value.length>3){
                msg="纵轴不能多余3个"
            }
            if(msg){
                ElMessage.warning({
                    message: msg 
                }); 
                return false;
            }
            let ruleInfo='';
            if(showMode.value=='list_sort' && !rule.value){//排名：排名规则
                ruleInfo=JSON.stringify({sort:rule.value});
            }
            else if( itemType.value==1 && isForecast.value==true){//单指标：是否有预测值的规则
                ruleInfo=JSON.stringify({isForecast:1})//是否预测，在数据库用数字存储。
            }
            else if((showMode.value=='chart_line' || showMode.value=='chart_bar') && yAxisType.value=='multiple'){//多指标：是否多轴的规则
                let yAxisItems=yAxisList.value.map(t=>{
                     return {item:t.item.join(',')} 
                    });
                ruleInfo=JSON.stringify({yAxisNum:yAxisList.value.length,item:yAxisItems.map(t=>t.item)})
            }
            console.log(items.value);
            let param={
                themeId:props.themeId,
                itemType:parseInt( itemType.value),
                items:JSON.stringify( items.value.map(t=>{
                    return {
                        id:t.id,
                        itemId:t.itemId,
                        objId:t.objId
                    }
                })),
                regionLevel:level.value,
                showMode:showMode.value,
                rule:ruleInfo,
                title:title.value,
                desc:itemDesc.value
            }
            console.log(param);
            if(type=='preview'){//预览
                let regionCode=''; 
                if(level.value==0){
                    regionCode='0';
                }
                if(level.value==1){
                    regionCode='440000'
                }
                else if(level.value==2){
                    regionCode='440300';
                }
                else if(level.value==3){
                    regionCode='440306';
                }
                sortDtInfo.value=[];
                isShowPreview.value=true;
                if(showMode.value=="list_sort"){//排名
                    tableLoadingShow.value=true;
                    console.log(items.value);
                    let p={
                        regionCode:regionCode,
                        regionRange:rule.value,
                        itemId:items.value[0].itemId,
                        ObjId:items.value[0].objId
                    }
                    console.log(p);
                    //获取指标项信息
                    getRegionItemSort(p).then(res=>{
                        tableLoadingShow.value=false;
                        console.log(res);
                        if(res.code==20000){
                            sortDtInfo.value=res.data.dataList;
                            dataDate.value=res.data.date;
                        }
                    });
                }
                else if(showMode.value=="chart_line" || showMode.value=="chart_bar"){//趋势，折线图
                    let p={
                        regionCode:regionCode,
                        itemId:JSON.stringify(items.value),
                        isForecast:isForecast.value
                    }
                    getRegionItemLine(p).then(res=>{
                        console.log(res);
                        if(res.code==20000){
                            let itemCycle=res.data.itemCycle;
                            let seriesAry=[];
                            let legendData=[];
                            let yAxisAry=[];
                            if(yAxisType.value=='multiple'){
                                yAxisList.value.forEach((t,i)=>{
                                    yAxisAry.push({
                                        type: 'value',
                                        position:i==0?'left':'right',
                                        alignTicks:true,
                                        offset:i>1?(i-1)*60:0,
                                        axisLine: {
                                            show: true,
                                            lineStyle: {
                                                color: 'gray',
                                            }
                                        },
                                        axisLabel:{
                                            margin : 3
                                        } 
                                    })
                                })
                            }
                            else{
                                yAxisAry.push({
                                    type: 'value'
                                })
                            }
                            res.data.item.forEach(m=>{
                                legendData.push(m.itemName+'('+m.itemUnit+')');
                                //如果是多轴，获取指标项所在多轴中的索引，即第几个轴
                                let index=0;
                                if(yAxisType.value=='multiple'){
                                    yAxisList.value.forEach((t,i)=>{
                                        if(t.item.indexOf(m.id)>-1){
                                            index=i;
                                            return;
                                        }
                                    })
                                }
                                seriesAry.push({
                                    name:m.itemName+'('+m.itemUnit+')',
                                    type:showMode.value.split('_')[1],
                                    smooth: true,
                                    data:m.itemValue,
                                    yAxisIndex:index
                                })
                                //如果有预测数据，增加预测曲线
                                let forecastList=res.data.item_forecast.find(t=>t.itemId==m.itemId);
                                if(forecastList ){
                                    let seriesName='预测值';//m.itemName+'('+m.itemUnit+')E';
                                    legendData.push(seriesName);
                                    seriesAry.push({
                                        name:seriesName,
                                        type:showMode.value.split('_')[1],
                                        itemStyle:{
                                            color:'#EE6666'
                                        },
                                        lineStyle:(showMode.value=="chart_line"?{
                                            type:'dashed',
                                        }:{}),
                                        smooth: true,
                                        data:forecastList.itemValue,
                                        yAxisIndex:index
                                    })
                                }
                            })
                            if(myChart){
                                myChart.dispose();//销毁实例
                            }
                            myChart =echarts.init(document.getElementById("chart"));
                            
                            let option = {
                                tooltip: {
                                trigger: 'axis'
                                },
                                legend: {
                                    type: 'scroll',
                                    pageIconColor:'#79bbff',
                                    // pageIconInactiveColor:'#aaa',
                                    pageTextStyle:{
                                        color:'#9CDCFE',
                                    },
                                    // top: '5',
                                    left: 'center',
                                    data:legendData,
                                },
                                dataZoom: [
                                {
                                    type: 'inside',
                                    start: 60,
                                    end: 100
                                },
                                {
                                    show: true,
                                    height: 16,
                                    bottom:20,
                                    start: 60,
                                    end: 100
                                }
                                ],
                                grid: {
                                    top: 60,
                                    bottom: 80,
                                    left:10,
                                    right:yAxisType.value=='multiple'?'15%':20,
                                    containLabel:true
                                },
                                xAxis: [
                                {
                                    type: 'category',
                                    axisTick: {
                                        alignWithLabel: true
                                    },
                                    axisLine: {
                                        onZero: false,
                                    },
                                    axisLabel: {
                                    formatter: function (params) {
                                        return parseTimeOfCycle(params,itemCycle)
                                    }
                                    },
                                    axisPointer: {
                                    label: {
                                        formatter: function (params) {
                                        return parseTimeOfCycle(params.value,itemCycle)
                                        }
                                    }
                                    },
                                    data: res.data.date
                                },
                                ],
                                yAxis: yAxisAry,
                                series: seriesAry,
                            };
                            myChart.setOption(option);
                        }
                    })
                }
                else if(showMode.value=="chart_pie"){//占比，饼图
                    let p={
                        regionCode:regionCode,
                        itemId:JSON.stringify(items.value)
                    }
                    getRegionItemPie(p).then(res=>{
                        console.log(res);
                        if(res.code==20000){
                            //dataDate.value=res.data.date
                            if(myChart){
                                myChart.dispose();//销毁实例
                            }
                            myChart =echarts.init(document.getElementById("chart"));
                            let option = {
                                graphic: 
                                {
                                    type: 'text',
                                    z: 100,
                                    left: 'center',
                                    top: 'middle',
                                    silent: true,
                                    invisible: !res.data?.dataList ||res.data?.dataList.length== 0?false:true, //是否可见，这里的意思是当没有数据时可见
                                    style: {
                                        fill: '#fff',
                                        //fontWeight: 'bold',
                                        text: '暂无数据',
                                        fontSize: '16px'
                                    }
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: '{a} <br/>{b} <br/> {c} ({d}%)'
                                },
                                legend: {
                                    type: 'scroll',
                                    pageIconColor:'#79bbff',
                                    // pageIconInactiveColor:'#aaa',
                                    pageTextStyle:{
                                        color:'#9CDCFE',
                                    },
                                    // top: '5',
                                    left: 'center',
                                    data:res.data?.dataList.map(t=>t.itemName+'('+t.itemUnit+')'),
                                },

                                series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: ['40%', '70%'],
                                    avoidLabelOverlap: false,
                                    emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    },
                                    label: {
                                        show: true,
                                        // fontSize: '40',
                                        fontWeight: 'bold'
                                    }
                                    },
                                    data: res.data?.dataList.map(t=>{
                                    return {
                                        value:t.itemValue,
                                        name:t.itemName+'('+t.itemUnit+')'
                                    }
                                    }),
                                }
                                ]
                            };
                            myChart.setOption(option);
                        }
                    });
                }
            }else{
                const result= await addAreaEco(param);
                if(result.code==20000){
                    //父页面的刷新
                    emit('refreshFun');
                }
                //记录用户操作日志
                addUserLog(route.name,route.fullPath,'添加区域经济',JSON.stringify(param));
            }
        }
        getUserFavoritesFun();
        //getAllRegionListFun();
        getItemCycleList();
        //getGradeList();
        return{
            //区域经济
            itemType,
            items,//选中的指标项
          
            showMode,//呈现方式
            //showModeChange,
            rule,//排名规则，默认同级
            isForecast,
            title,
            itemDesc,
            swithChangeFun,
            getUserFavoritesFun,
            userFavoritesList,
            favId,
            favList,
            getFavListFun,
            isShowItem,
            showItemFun,
            selItemFun,
            //regionList,
            delItem,
          
            submitFun,
            itemCycleList,
            itemCycle,
            regionLevelList,
            level,
            cgChangeFun,
            yAxisType,
            yAxisList,
            isCurDate,
            isShowPreview,
            sortDtInfo,
            tableLoadingShow,
            radioChange,
            dataDate
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.fav{
    height: 300px;
    background: rgba($themeColor, 0.1);
    border-radius: 2px;
    position: relative;
    margin-bottom: 10px;
}
.fav-left {
    position: relative;
    float: left;
    width: 120px;
    padding:10px;
    margin-right: -1px;
    font-size: 14px;
    box-sizing: border-box;
    height: 100%;
    ul li{
        cursor: pointer;
        padding:6px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ul li:hover{
        background-color: rgba($themeColor, 0.1);
    }
    ul li.cur{
        background: rgba($themeColor, 0.3);
    }
    
}
.fav-right {
    float: left;
    width: calc(100% - 120px);
    border-left: 1px solid rgba($themeColor, 0.3);
    padding: 10px;
    height: 100%;
    overflow-y:auto ;
}
.item-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    padding: 0 9px;
    margin: 5px;
    font-size: 12px;
    line-height: 1;
    box-sizing: border-box;
    white-space: nowrap;
    cursor: pointer;
    &:hover{
       color: $specialtext-color;
    }
}
.item-tag.is-disabled,.item-tag.is-disabled:hover{
    color: #aaa;
}
.item-tag.is-sel{
    color: $specialtext-color;
}
.c-close{
    font-size: 20px;
    position: absolute;
    right: -0px;
    top: -12px;
    cursor: pointer;
    color:#d5d5d5;
    &:hover{
        color: #aaa;
    }
}
.n-tabs{
    position: relative;
    border-bottom: 1px solid $bordercolor;
}
.n-btn {
    display: inline-block;
    line-height: 40px;
    height: 40px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    bottom: -1px;
}
.active,.n-btn:hover{
    color: $specialtext-color;
    border-bottom: 2px solid $specialtext-color;
}
::v-deep .el-popper__arrow {
    &::before{
    border: none;
    background: #fff !important;
    }
  }
</style>